import { gql } from '@apollo/client';
import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export const QUERY_GET_DRIVERS = gql`
  query GetDrivers {
    drivers @rest(type: "Drivers", path: "/drivers", endpoint: "carrier") {
      drivers @type(name: "Driver") {
        uuid
        identityUuid
        carrierUuid
        carrierName
        firstName
        lastName
        email
        phoneNumber
        startDate
        driverExternalId
        carrierExternalId
        enabled
        attributes {
          CostCenter
          DriverPin
        }
        carrierPublicId
        carrierProgramExternalId
      }
    }
  }
`;

const apiUrl = Config.CARRIER_API_URL;

export async function getCarrierDrivers(carrierUuid: string) {
  return await axios
    .get(`${apiUrl}/carriers/${carrierUuid}/drivers`)
    .then((response) => response)
    .catch((error) => error);
}
