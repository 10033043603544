import { useQuery } from '@apollo/client';

import { Table } from '@/aggregator/ui/data-display/Table';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import * as queries from './queries';
import * as types from './types';
import { getColumns } from './helpers/getColumns';

export function Merchants() {
  const { data, loading, error } = useQuery<{
    getMerchants: types.MerchantType[];
  }>(queries.QUERY_GET_MERCHANTS);

  if (loading) return <CircularProgress />;
  if (error) return <div>error</div>;
  if (!data) return <p>No data available</p>;

  const merchants = data.getMerchants;
  const columns = getColumns();

  return <Table title="Merchants" data={merchants} columns={columns} />;
}
