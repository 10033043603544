export namespace CarrierStatus {
  const statusTypes = [
    {
      name: 'Active',
      value: 'CARRIER_STATUS_ACTIVE',
    },
    {
      name: 'Inactive',
      value: 'CARRIER_STATUS_INACTIVE',
    },
    {
      name: 'Suspended',
      value: 'CARRIER_STATUS_SUSPENDED',
    },
    {
      name: 'Deleted',
      value: 'CARRIER_STATUS_DELETED',
    },
  ];
  export function getByValue(typeValue: string) {
    return statusTypes.find((x) => x.value === typeValue);
  }
}
