import * as React from 'react';
import { useApolloClient } from '@apollo/client';

import { Formik, Form } from 'formik';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography,
} from '@mui/material';

import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { currencyFormatter } from '@/aggregator/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';
import {
  getCarrierCreditLineService,
  setCarrierCreditLineService,
} from '../../queries';

const validationSchemaObject = Yup.object().shape({
  amount: Yup.number().required('Amount is required'),
});

export function CarrierCreditLine() {
  const client = useApolloClient();
  const { carrierId = '' } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);

  const [data, setData] = React.useState({
    amount: 0,
    asOfDate: '',
    carrierUuid: '',
  });

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  const handleOnClose = () => {
    navigate(PathPrivate.Carriers);
  };

  React.useEffect(() => {
    if (!carrierId) {
      return;
    }
    getCarrierCreditLineService(carrierId).then((response) => {
      setData(response.data.creditLine);
      setLoading(false);
    });
  }, [carrierId]);

  if (loading) return <CircularProgress />;
  if (!carrier) return <div>Carrier not found</div>;

  return (
    <Formik
      initialValues={{
        amount: '',
        asOfDate: '',
      }}
      validationSchema={validationSchemaObject}
      enableReinitialize
      onSubmit={(values) => {
        const creditLineRequest = {
          amount: Number(values.amount).toFixed(2),
          asOfDate: format(new Date(), 'yyyyMMdd'),
          carrierUuid: carrierId,
        };
        setCarrierCreditLineService(creditLineRequest, carrierId)
          .then(() => {
            toast.success('Credit Line set.');
          })
          .catch((e: any) => {
            if (e.response) {
              toast.error(e.response.data.message);
            } else {
              toast.error(e.message);
            }
          })
          .finally(handleOnClose);
      }}
    >
      {({ values, errors, handleChange, handleSubmit }) => (
        <Dialog open onClose={handleOnClose}>
          <DialogTitle sx={{ textAlign: 'center' }}>
            Carrier: {carrier.name}
          </DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box sx={{ mx: 1 }}>
                <Typography variant="h6" component="h2" mb={2}>
                  Set Carrier Credit Line
                </Typography>
                {data?.carrierUuid && (
                  <Alert severity="info">
                    Credit Line set to {currencyFormatter.format(data?.amount)}{' '}
                    as of {format(parseISO(data?.asOfDate), 'MM/dd/yyyy')}
                  </Alert>
                )}
                <FormControl sx={{ my: 2, width: '100%' }}>
                  <TextField
                    id="outlined-basic"
                    label="Amount"
                    variant="outlined"
                    fullWidth
                    name="amount"
                    value={values.amount}
                    error={!!errors.amount}
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleOnClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" onClick={handleSubmit}>
                Save
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
