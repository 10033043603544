import { gql } from '@apollo/client';
import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export const QUERY_GET_CARRIER_READINESS = gql`
  query GetCarrierReadiness($carrierUuids: String!) {
    status(carrierUuids: $carrierUuids)
      @rest(
        type: "CarriersReadiness"
        path: "/carriers/readiness?carrierUuids={args.carrierUuids}"
        endpoint: "carrier"
      ) {
      status @type(name: "CarrierReadiness") {
        carrierUuid
        limitsReady {
          status
        }
        promptsReady {
          status
        }
        creditLineReady {
          status
        }
        feesReady {
          status
        }
        driverReady {
          status
        }
        fleetAppReady {
          status
        }
      }
    }
  }
`;

export async function getCarrierReadiness(carrierUuid: string) {
  return await axios
    .get(
      `${Config.CARRIER_API_URL}/carriers/readiness?carrierUuids=${carrierUuid}`,
    )
    .then((response) => response)
    .catch((error) => error);
}
