import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';

type ActionsMenuPropsType = {
  uuid: string;
};

export function ActionsMenu(props: ActionsMenuPropsType) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleOnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
  };

  const handleOnClickItem = (path: PathPrivate) => {
    handleOnClose();
    const pathUpdated = generatePath(path, {
      merchantId: props.uuid,
    });
    navigate(pathUpdated);
  };

  const options = [
    {
      name: 'View Locations',
      path: PathPrivate.MerchantLocations,
    },
  ];

  return (
    <div>
      <IconButton
        id="long-button"
        disabled={false}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => handleOnClick(event)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOnClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => {
              handleOnClickItem(option.path);
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
