import * as MUI from '@mui/material';

export function CircularProgress() {
  return (
    <MUI.Box
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <MUI.CircularProgress />
    </MUI.Box>
  );
}
