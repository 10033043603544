import { Box, BoxProps } from '@mui/material';

type StatusPillProps = {
  active?: boolean;
  text?: string;
  props?: BoxProps;
};

export function StatusPill({ active, text, props }: StatusPillProps) {
  const pillText = () => {
    if (text) {
      return text;
    }

    return active ? 'Active' : 'Inactive';
  };

  return (
    <Box
      width="max-content"
      px="12px"
      py="4px"
      bgcolor={active ? 'green' : '#cc0000'}
      color="white"
      fontSize={12}
      fontWeight={600}
      borderRadius={2}
      {...props}
    >
      {pillText()}
    </Box>
  );
}
