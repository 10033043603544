import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { PathPrivate } from '@/aggregator/paths/constants';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import { CarrierReadinessChip } from './components/CarrierReadinessChip';
import * as helpers from './helpers';
import * as queries from './queries';
import { isObject, has } from 'lodash';

type StatusType = {
  [key: string]: {
    status: string;
    detail: string;
  } | null;
};

export function CarrierReadiness() {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { carrierId = '' } = useParams();

  const [status, setStatus] = React.useState<StatusType[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    const getData = async () => {
      await queries.getCarrierReadiness(carrierId).then((response: any) => {
        if (response.isAxiosError) {
          throw Error(response.response.data.message);
        } else {
          setStatus(response.data.status);
        }
        setLoading(false);
      });
    };
    getData();
  }, []);

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  if (!carrier) return <div>Carrier not found</div>;
  if (loading) return <CircularProgress />;

  function getReadinessUI() {
    return Object.entries(status[0]).map(([key, value]) => {
      if (value === null || !has(value, 'status') || key === 'feesReady') {
        return null;
      } else if (isObject(value) && has(value, 'status')) {
        return (
          <Box
            key={key}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            mb={1}
          >
            <Box mr={2}>{helpers.getFormattedReadinessKey(key)}</Box>
            <CarrierReadinessChip
              readinessStatus={helpers.getReadinessStatus(value.status)}
            />
          </Box>
        );
      }

      return null;
    });
  }

  return (
    <Dialog open onClose={() => navigate(PathPrivate.Carriers)}>
      <>
        <DialogTitle>{carrier.name}</DialogTitle>
        <DialogContent>
          <Box
            mb={2}
            mt={1}
            display="flex"
            alignItems="center"
            flexDirection="column"
            minWidth={500}
          >
            {getReadinessUI()}
          </Box>
        </DialogContent>
      </>
    </Dialog>
  );
}
