import axios from 'axios';
import { gql } from '@apollo/client';
import { Config } from '@/aggregator/config/env';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';

export const MUTATION_UPDATE_CARRIER = gql`
  mutation UpdateCarrier($carrier: UpdateCarrierInput!) {
    updateCarrier(carrier: $carrier)
      @rest(
        type: "UpdateCarrier"
        path: "/carriers/{args.carrier.uuid}"
        method: "POST"
        endpoint: "carrier"
        bodyKey: "carrier"
      ) {
      carrier @type(name: "Carrier") {
        ...FragmentCarrier
      }
    }
  }
  ${queriesCarriers.FRAGMENT_CARRIER}
`;

export async function updateCarrier(carrier: any) {
  const isDtnaCarrier = carrier.isDtnaCarrier === 'true';
  delete carrier.isDtnaCarrier;
  carrier.isDtnaCarrier = isDtnaCarrier;

  return await axios
    .post(`${Config.CARRIER_API_URL}/carriers/${carrier.uuid}`, { carrier })
    .then((response) => response)
    .catch((error) => error);
}
