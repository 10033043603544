import * as React from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Button } from '@mui/material';

import { MUIDataTableColumnDef } from 'mui-datatables';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Table } from '@/aggregator/ui/data-display/Table';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';

import * as queriesMerchant from '@/aggregator/pages/private/merchants/queries';
import * as typesMerchant from '@/aggregator/pages/private/merchants/types';

export const MerchantLocations = () => {
  const client = useApolloClient();
  const { merchantId = '' } = useParams();
  const [loading, setLoading] = useState(true);
  const [locations, setLocations] = useState([]);

  const [getMerchants, queryMerchants] = useLazyQuery<{
    getMerchants: typesMerchant.MerchantType[];
  }>(queriesMerchant.QUERY_GET_MERCHANTS);

  const merchant = queriesMerchant.getMerchantFromFragment(client, merchantId);

  React.useEffect(() => {
    if (!merchant) {
      getMerchants();
    }
  }, [merchant]);

  useEffect(() => {
    const getData = async () => {
      await queriesMerchant
        .listMerchantLocationsServiceByCarrier(merchantId)
        .then((response: any) => {
          setLocations(response.data.locations);
        });
      setLoading(false);
    };
    getData();
  }, []);

  if (loading || queryMerchants.loading) return <CircularProgress />;
  if (!merchantId || !merchant) return <div>Merchant not found</div>;

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'Ext ID',
      name: 'externalId',
    },
    {
      label: 'TID',
      name: 'tid',
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Store #',
      name: 'storeNumber',
    },
    {
      label: 'Address',
      name: 'address',
      options: {
        customBodyRender: (address) => {
          if (address) {
            return (
              <>
                {`${address.street1}, ${address.city}, ${address.region}, ${address.postalCode}`}
              </>
            );
          }
        },
      },
    },
    {
      label: 'Lat',
      name: 'lat',
    },
    {
      label: 'Long',
      name: 'long',
    },
    {
      label: 'Status',
      name: 'active',
      options: {
        customBodyRender: (value) =>
          value ? (
            <Button
              size="small"
              sx={{
                color: '#181818',
                textTransform: 'none',
                padding: 0,
                pointerEvents: 'none',
              }}
            >
              <LocationOnIcon /> Active
            </Button>
          ) : (
            <Button
              sx={{ textTransform: 'none', padding: 0 }}
              size="small"
              disabled
            >
              <LocationOffIcon /> Inactive
            </Button>
          ),
      },
    },
  ];

  return (
    <>
      <Table
        title={`Locations for Merchant: ${merchant.name}`}
        data={locations}
        columns={columns}
      />
      <Outlet />
    </>
  );
};
