import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

export function NotFound() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Typography variant="h3">404 - page not found</Typography>
    </Grid>
  );
}
