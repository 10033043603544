import axios from 'axios';
import * as auth from './auth';

axios.defaults.withCredentials = true;

axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${auth.getToken()}`,
      'Content-Type': 'application/json',
    };
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  },
);
