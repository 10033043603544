import * as React from 'react';
import { useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import * as auth from '@/aggregator/config/auth';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import { Transactions } from './components/Transactions';
import { DashboardStats } from './components/DashboardStats';
import { getChartDataDay } from './helpers/getChartDataDay';
import { getChartData } from './helpers/getChartData';
import * as queries from './queries';
import * as types from './types';

const QUERY_PARAM_TIME_FRAME = 'timeFrame';

function getQueryParamTimeFrame(
  queryStringValue: string | null,
): types.DashBoardTime {
  if (
    Object.values(types.DashBoardTime).includes(
      queryStringValue as types.DashBoardTime,
    )
  ) {
    return queryStringValue as types.DashBoardTime;
  }

  return types.DashBoardTime.DAY;
}

export function Dashboard() {
  const programUuid = auth.getProgramUuid();
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const timeFrame = getQueryParamTimeFrame(query.get(QUERY_PARAM_TIME_FRAME));

  const { data, loading, error } = useQuery<{
    getDashboardInfo: types.DashboardInfo;
  }>(queries.QUERY_GET_DASHBOARD_INFO, {
    variables: { programUuid, timeFrame },
  });

  const handleOnClickPeriod = (value: string) => {
    query.set(QUERY_PARAM_TIME_FRAME, value);
    navigate({ search: query.toString() });
  };

  const chartData = React.useMemo(() => {
    if (!data?.getDashboardInfo.dataPoints) return [];
    const { dataPoints } = data.getDashboardInfo;

    const now = new Date();

    if (timeFrame === types.DashBoardTime.DAY) {
      return getChartDataDay({
        now,
        dataPoints,
      });
    }

    if (timeFrame === types.DashBoardTime.WEEK) {
      return getChartData({
        days: 7,
        now,
        dataPoints,
      });
    }

    if (timeFrame === types.DashBoardTime.MONTH) {
      return getChartData({
        days: 30,
        now,
        dataPoints,
      });
    }

    return data.getDashboardInfo.dataPoints;
  }, [data?.getDashboardInfo, timeFrame]);

  if (loading) return <CircularProgress />;
  if (error) return <div>error</div>;
  if (!data) return <p>No data available</p>;

  return (
    <Box sx={{ width: '100%' }}>
      <Transactions
        timeFrame={timeFrame}
        handleOnClickPeriod={handleOnClickPeriod}
        chartData={chartData}
      />
      <DashboardStats dashboardInfo={data.getDashboardInfo} />
    </Box>
  );
}
