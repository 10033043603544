import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export async function addUser(programUuid: string, values: any) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/programs/${programUuid}/users`, values)
    .then((response) => response)
    .catch((error) => error);
}

export async function setPassword(payload: any) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/users/password`, payload)
    .then((response) => response)
    .catch((error) => error);
}
