import toast from 'react-hot-toast';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Tooltip } from '@mui/material';
import { ActionsMenu } from '../components/ActionMenu';

export function getColumns() {
  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'ID',
      name: 'uuid',
    },
    {
      name: 'uuid',
      options: {
        display: false,
      },
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Map Marker',
      name: 'mapMarker',
      options: {
        customBodyRender: (url) => {
          if (!url) return <></>;
          return (
            <Tooltip
              title={url}
              onClick={() => {
                navigator.clipboard.writeText(url);
                toast.success('marker image url copied');
              }}
            >
              <img alt="map marker" src={url} width={34} />
            </Tooltip>
          );
        },
      },
    },
    {
      label: ' ',
      name: 'uuid',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRender: (uuid) => {
          return <ActionsMenu uuid={uuid} />;
        },
      },
    },
  ];

  return columns;
}
