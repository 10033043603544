import 'chart.js/auto';
import { Box, Button, Typography } from '@mui/material';
import { TransactionChart } from './components/TransactionChart';
import * as types from '../../types';

const DURATION_FORMATS = {
  [types.DashBoardTime.DAY]: types.DurationFormats.MonthDayHour,
  [types.DashBoardTime.WEEK]: types.DurationFormats.YearMonthDay,
  [types.DashBoardTime.MONTH]: types.DurationFormats.YearMonthDay,
};

type TransactionPropsType = {
  timeFrame: types.DashBoardTime;
  handleOnClickPeriod: (value: string) => void;
  chartData: types.DataPoint[];
};

export function Transactions(props: TransactionPropsType) {
  const { timeFrame, handleOnClickPeriod, chartData } = props;
  const xAxisFormat = DURATION_FORMATS[timeFrame];

  const columns = [
    ['24 HOURS', types.DashBoardTime.DAY],
    ['7 DAYS', types.DashBoardTime.WEEK],
    ['30 DAYS', types.DashBoardTime.MONTH],
  ].map(([label, value]) => ({ label, value }));

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        borderRadius: 2,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        p: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: 'center',
        }}
      >
        Transactions
      </Typography>
      <TransactionChart chartData={chartData} xAxisFormat={xAxisFormat} />
      <Box
        flex={1}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        my={3}
      >
        {columns.map((c) => {
          return (
            <Box key={c.label}>
              <Button
                onClick={() => handleOnClickPeriod(c.value)}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  color: 'black',
                  backgroundColor:
                    timeFrame === c.value
                      ? 'rgba(0, 0, 0, 0.1)'
                      : 'transparent',
                }}
              >
                <Typography fontSize={16} fontWeight={600}>
                  {c.label}
                </Typography>
              </Button>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}
