import { groupBy, map, sumBy } from 'lodash';
import { format } from 'date-fns';
import { subDays, startOfDay, addDays } from 'date-fns';
import * as types from '../types';

type GetChartDataWeekOptionsType = {
  now: Date;
  dataPoints: types.DataPoint[];
  days: number;
};

export function getChartData(options: GetChartDataWeekOptionsType) {
  const { now, dataPoints, days } = options;

  const pastDays = subDays(now, days - 1); // days including today
  const endOfToday = addDays(startOfDay(now), 1); // End of today

  // Filter data for the past `days` including today
  const filteredData = dataPoints.filter((point) => {
    const pointTime = new Date(point.time);
    return pointTime >= pastDays && pointTime < endOfToday;
  });

  // Group data by day
  const groupedByDate = groupBy(filteredData, (item: any) => {
    return format(new Date(item.time), 'yyyy-MM-dd'); // Group by day
  });

  // Aggregate the total gallons for each day and create an array
  const aggregatedData = map(groupedByDate, (group, date) => {
    return {
      time: date, // Use the date string directly
      gallon: sumBy(group, 'gallon'), // Sum up gallons for the day
      dollar: sumBy(group, 'dollar'), // Sum up dollars for the day
    };
  });

  // Generate empty slots for the last `days` if there are no data points for certain days
  const completeData: types.DataPoint[] = [];
  for (let i = 0; i < days; i++) {
    const date = format(addDays(pastDays, i), 'yyyy-MM-dd');
    const found = aggregatedData.find((d) => d.time === date);
    completeData.push(found ? found : { time: date, gallon: 0, dollar: 0 });
  }

  return completeData;
}
