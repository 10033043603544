import * as React from 'react';
import { useQuery } from '@apollo/client';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { PrimaryButton } from '@/aggregator/ui/inputs/Button';
import { Table } from '@/aggregator/ui/data-display/Table';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import * as auth from '@/aggregator/config/auth';
import { ActionsMenu } from './components/ActionsMenu';
import * as helpers from './helpers';
import * as types from './types';
import * as queries from './queries';
import { Box } from '@mui/material';
import { PathPrivate } from '@/aggregator/paths/constants';

export function Carriers() {
  const programUuid = auth.getProgramUuid();
  const navigate = useNavigate();
  const location = useLocation();

  const { data, loading, error, refetch } = useQuery<{
    getCarriers: types.CarrierType[];
  }>(queries.QUERY_GET_CARRIERS, {
    variables: { programUuid },
  });

  React.useEffect(() => {
    if (location.state?.shouldRefetch) {
      refetch();
    }
  }, [location.state, refetch]);

  if (loading) return <CircularProgress />;
  if (error) return <div>error</div>;
  if (!data) return <p>No data available</p>;
  const carriers = data.getCarriers;

  const handleExportAllTransactionsClick = () => {
    navigate(PathPrivate.CarrierExportAllTransactions);
  };

  const handleShowProgramCreditDetails = () => {
    navigate(PathPrivate.CarrierProgramCreditDetails);
  };

  const handleShowMarketingFees = () => {
    navigate(PathPrivate.ProgramMarketingFees);
  };

  const columns: MUIDataTableColumnDef[] = [
    {
      name: 'uuid',
      options: {
        display: false,
      },
    },
    {
      label: 'External ID',
      name: 'externalId',
    },
    {
      label: 'Public ID',
      name: 'publicId',
    },
    {
      label: 'Name',
      name: 'name',
    },
    {
      label: 'Street',
      name: 'address.street1',
    },
    {
      label: 'City',
      name: 'address.city',
    },
    {
      label: 'State',
      name: 'address.state',
    },
    {
      label: 'Dot #',
      name: 'dotNumber',
      options: {
        display: false,
      },
    },
    {
      label: 'Carrier Status',
      name: 'status',
      options: {
        customBodyRender: (value) => {
          return helpers.CarrierStatus.getByValue(value)?.name;
        },
      },
    },
    {
      label: 'Status',
      name: 'overallReadiness',
      options: {
        display: false,
      },
    },
    {
      label: ' ',
      name: 'uuid',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ActionsMenu
              dataIndex={dataIndex}
              uuid={carriers[dataIndex].uuid}
            />
          );
        },
      },
    },
  ];

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Box mr={1}>
          <PrimaryButton onClick={handleShowMarketingFees}>
            Set Marketing Fees
          </PrimaryButton>
        </Box>
        <Box mr={1}>
          <PrimaryButton onClick={handleShowProgramCreditDetails}>
            Show Program Credit Details
          </PrimaryButton>
        </Box>
        <PrimaryButton onClick={handleExportAllTransactionsClick}>
          Export All Transactions
        </PrimaryButton>
      </Box>
      <Table title="Carriers" data={carriers} columns={columns} />
      <Outlet />
    </>
  );
}
