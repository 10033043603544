import { gql } from '@apollo/client';
import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export const QUERY_GET_CARRIER_BALANCE = gql`
  query GetCarrierBalance($carrierUuids: String!) {
    balance(carrierUuids: $carrierUuids)
      @rest(
        type: "Balance"
        path: "/carriers/{args.carrierUuids}/balance"
        endpoint: "carrier"
      ) {
      balanceAmount
      availableFunds
      balances
    }
  }
`;

export async function getCarrierBalance(carrierUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/carriers/${carrierUuid}/balance`)
    .then((response) => response)
    .catch((error) => error);
}
