import * as React from 'react';
import { Navigate } from 'react-router-dom';
import * as auth from '@/aggregator/config/auth';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';

// If user is logged in execute doLogout if not redirect
// "home" and get redirected to login page
export function Logout() {
  React.useEffect(() => {
    if (auth.isLoggedIn()) {
      auth.doLogout();
    }
  }, [auth.doLogout, auth.isLoggedIn]);

  if (auth.isLoggedIn()) return <CircularProgress />;

  return <Navigate replace to="/" />;
}
