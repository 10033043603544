import { gql, ApolloClient } from '@apollo/client';
import { Config } from '@/aggregator/config/env';
import axios from 'axios';
import * as types from './types';

export const FRAGMENT_MERCHANT = gql`
  fragment FragmentMerchant on ExportMerchant {
    uuid
    name
    mapMarker
  }
`;

export const QUERY_GET_MERCHANTS = gql`
  query GetMerchants {
    getMerchants {
      ...FragmentMerchant
    }
  }
  ${FRAGMENT_MERCHANT}
`;

export const GET_MERCHANT_LOCATIONS = gql`
  query GetLocations($merchantIds: [String]) {
    getLocations(merchantIds: $merchantIds) {
      id
      name
      address {
        street
        city
        state
        zipCode
      }
      lat
      long
      mapMarkerUrl
      tid
      siteStatus
      externalId
      storeNumber
      active
    }
  }
`;

function getMerchantCacheId(merchantUuid: string) {
  return `ExportMerchant:{"uuid":"${merchantUuid}"}`;
}

export function getMerchantFromFragment(
  client: ApolloClient<object>,
  merchantId: string,
) {
  const merchant = client.readFragment<types.MerchantType>({
    id: getMerchantCacheId(merchantId),
    fragment: FRAGMENT_MERCHANT,
  });

  return merchant;
}

export async function listMerchantLocationsServiceByCarrier(
  merchantUuid: string,
) {
  return await axios
    .get(`${Config.MERCHANT_API_URL}/staff/merchants/${merchantUuid}/locations`)
    .then((response) => response)
    .catch((error) => error);
}
