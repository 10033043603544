export enum PathPrivate {
  Dashboard = '/dashboard',
  Carriers = '/carriers',
  CarrierReadiness = '/carriers/:carrierId/readiness',
  CarrierDiscountBalance = '/carriers/:carrierId/discount-balance',
  CarrierBookPayment = '/carriers/:carrierId/book-payment',
  CarrierExportInvoice = '/carriers/:carrierId/export-invoice',
  CarrierExportTransactions = '/carriers/:carrierId/export-transactions',
  CarrierExportAllTransactions = '/carriers/export-all-transactions',
  CarrierEdit = '/carriers/:carrierId/edit',
  CarrierCreditLine = '/carriers/:carrierId/credit-line',
  CarrierDrivers = '/carriers/:carrierId/drivers',
  CarrierAddDriver = '/carriers/:carrierId/drivers/add-driver',
  CarrierPrompts = '/carriers/:carrierId/prompts',
  CarrierDriversResetPassword = '/carriers/:carrierId/drivers/:driverId/reset-password',
  CarrierLimits = '/carriers/:carrierId/limits',
  CarrierImportDrivers = '/carriers/:carrierId/drivers/import-drivers',
  CarrierProgramCreditDetails = '/carriers/credit-details',
  ProgramMarketingFees = '/carriers/program-marketing-fees',
  Merchants = '/merchants',
  MerchantLocations = '/merchants/:merchantId/locations',
  Users = '/users',
  UserResetPassword = '/users/:userId/reset-password',
  UsersAddUser = '/users/add-user',
}

export enum PathPublic {
  Index = '/',
  Login = '/login',
  Logout = '/logout',
  Denied = '/access-denied',
  NotFound = '/not-found',
}
