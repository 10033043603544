export function getReadinessStatus(status: string) {
  if (status === undefined) {
    return undefined;
  }

  return status === 'READY_STATUS_NOT_READY' ? 'not_ready' : 'ready';
}

export function getFormattedReadinessKey(readinessKey: string) {
  const words = readinessKey.replace('Ready', '').split(/(?=[A-Z])/);

  // Capitalize each word and remove empty strings
  const capitalizedWords = words
    .filter((word) => word !== '')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  const formattedString = capitalizedWords.join(' ');

  return formattedString;
}
