import {
  subHours,
  isAfter,
  format,
  setMinutes,
  setSeconds,
  setMilliseconds,
} from 'date-fns';
import * as types from '../types';

type GetChartDataDayOptionsType = {
  now: Date;
  dataPoints: types.DataPoint[];
};

export function getChartDataDay(options: GetChartDataDayOptionsType) {
  const { now, dataPoints } = options;
  const past24Hours = subHours(now, 24);

  // Filter data for the past 24 hours
  const filteredData = dataPoints.filter((point) => {
    const pointTime = new Date(point.time);
    return isAfter(pointTime, past24Hours);
  });

  // Sort the filtered data by time in ascending order
  filteredData.sort(
    (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
  );

  // Generate empty data points for the last 24 hours
  const timeSlots: types.DataPoint[] = [];
  for (let i = 0; i <= 24; i++) {
    const slotTime = subHours(now, 24 - i);
    const roundedSlotTime = setMinutes(
      setSeconds(setMilliseconds(slotTime, 0), 0),
      0,
    ).toISOString(); // Round to the nearest hour
    timeSlots.push({
      time: roundedSlotTime,
      gallon: 0,
      dollar: 0,
    });
  }

  // Ensure the current time slot is included with the latest data if available
  const nowFormatted = format(now, 'yyyy-MM-dd HH:mm');
  if (
    !filteredData.some(
      (d) => format(new Date(d.time), 'yyyy-MM-dd HH:mm') === nowFormatted,
    )
  ) {
    filteredData.push({ time: now.toISOString(), gallon: 0, dollar: 0 });
  }

  // Merge with filtered data without duplicating the current time slot
  const mergedData = timeSlots.map((slot) => {
    const found = filteredData.find(
      (point) =>
        format(new Date(point.time), 'yyyy-MM-dd HH') ===
        format(new Date(slot.time), 'yyyy-MM-dd HH'),
    );
    return found ? found : slot;
  });

  // Ensure the first data point matches the current time's minute
  if (mergedData.length > 1) {
    mergedData[0] = {
      ...mergedData[0],
      time: setMinutes(
        setSeconds(setMilliseconds(new Date(mergedData[0].time), 0), 0),
        now.getMinutes(),
      ).toISOString(),
    };
  }

  // Ensure the last data point is exactly the current time
  mergedData[mergedData.length - 1] = {
    time: now.toISOString(),
    gallon: 0,
    dollar: 0,
  };

  return mergedData;
}
