import { Toaster } from 'react-hot-toast';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/system';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme } from '@mui/material/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../index.css';

const thm = createTheme({
  palette: {
    primary: {
      main: '#000',
    },
    background: {
      default: '#fff',
    },
  },
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        toolButton: {
          marginLeft: '15px',
          paddingLeft: 0,
        },
      },
    },
    MUIDataTableViewCol: {
      styleOverrides: {
        title: {
          padding: '14px 10px 0px 10px',
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        // @ts-expect-error: TS2322
        labelPlacementEnd: {
          paddingLeft: '15px',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginTop: 15,
        },
        displayedRows: {
          marginTop: 15,
        },
      },
    },
  },
});

export function Root() {
  return (
    <ThemeProvider theme={thm}>
      <CssBaseline />
      <Outlet />
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            borderRadius: '10px',
            background: '#181818',
            color: '#fff',
          },
        }}
      />
    </ThemeProvider>
  );
}
