import DownloadIcon from '@mui/icons-material/Download';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { FC, useState } from 'react';
import toast from 'react-hot-toast';
import { useCSVDownloader, useCSVReader } from 'react-papaparse';
import { useNavigate, useParams } from 'react-router-dom';
import {
  PrimaryButton,
  SecondaryButton,
  TertiaryButton,
} from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import { addDriverBulk } from '../../queries';
import { format, parseISO } from 'date-fns';
import { generatePath } from '@/aggregator/paths/helpers';

const importerColumns = [
  'driverExternalId',
  'firstName',
  'lastName',
  'phoneNumber',
  'email',
  'type',
  'date',
];

const prepareData = (csvData: any) => {
  delete csvData[0]; // assumes is the header and removes it

  const lines: any = { drivers: [] };
  csvData.map((line: any) => {
    const [
      driverExternalId,
      firstName,
      lastName,
      phoneNumber,
      email,
      type,
      date,
    ] = line;

    const startDate = format(parseISO(date), 'yyyyMMdd');

    return lines.drivers.push({
      driverExternalId,
      firstName,
      lastName,
      email,
      phoneNumber,
      type,
      startDate,
    });
  });

  return lines;
};

const validateCsvColumns = (
  importerColumns: string[] | undefined,
  csvHeaders: string[],
) => {
  if (importerColumns === undefined) throw Error('importerColumns is missing');
  if (csvHeaders.join() === importerColumns.join()) {
    return true;
  }

  const errorMsg = `CSV columns should match: ${importerColumns
    .map((h) => h)
    .join('\t')}`;

  toast.error(errorMsg, {
    duration: 6000,
  });

  throw Error(errorMsg);
};

export const CarrierImportDrivers: FC = () => {
  const { CSVReader } = useCSVReader();
  const { CSVDownloader } = useCSVDownloader();
  const [disabled, setDisabled] = useState(true);
  const [csvData, setCsvData] = useState();
  const navigate = useNavigate();
  const { carrierId = '' } = useParams();

  const handleClose = (shouldRefetch: boolean) => {
    const pathUpdated = generatePath(PathPrivate.CarrierDrivers, {
      carrierId: carrierId,
    });
    navigate(pathUpdated, { state: { shouldRefetch } });
  };

  const handleSubmit = async () => {
    await addDriverBulk(prepareData(csvData), carrierId)
      .then(() => {
        handleClose(true);
        toast.success('Drivers added');
      })
      .catch((error) => {
        toast.error(`Add driver bulk failed, error details: ${error.message}`);
      });
  };

  return (
    <Dialog
      open
      onClose={() => handleClose(false)}
      aria-labelledby="modal-title"
    >
      <DialogTitle>Import CSV</DialogTitle>
      <DialogContent>
        <CSVReader
          onUploadAccepted={(results: any) => {
            if (validateCsvColumns(importerColumns, results.data[0])) {
              setCsvData(results.data);
              setDisabled(false);
            }
          }}
        >
          {({ getRootProps, acceptedFile }: any) => (
            <>
              <TertiaryButton {...getRootProps()}>Select file</TertiaryButton>
              {` `}
              {acceptedFile && acceptedFile.name}
            </>
          )}
        </CSVReader>
      </DialogContent>
      <DialogActions>
        {importerColumns && (
          <CSVDownloader
            filename={'csv-importer-template'}
            bom={true}
            config={{
              delimiter: ';',
            }}
            header={false}
            data={[importerColumns]}
          >
            <SecondaryButton endIcon={<DownloadIcon />}>
              Download template
            </SecondaryButton>
          </CSVDownloader>
        )}

        <SecondaryButton onClick={() => handleClose(false)}>
          Close
        </SecondaryButton>
        <PrimaryButton
          endIcon={<FileUploadIcon />}
          onClick={handleSubmit}
          disabled={disabled}
        >
          Import
        </PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
