import * as React from 'react';
import { useApolloClient } from '@apollo/client';
import { format, isValid, parse } from 'date-fns';
import { useNavigate, useParams } from 'react-router-dom';
import { FieldArray, Formik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import {
  Dialog,
  TextField,
  DialogActions,
  Alert,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Switch,
  Typography,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import { PathPrivate } from '@/aggregator/paths/constants';
import { getLimitsService, setLimitsService } from '../../queries';

const FUEL_PRODUCTS = [
  'PRODUCT_CATEGORY_REEFER',
  'PRODUCT_CATEGORY_DIESEL',
  'PRODUCT_CATEGORY_DEF',
  'PRODUCT_CATEGORY_NON_DIESEL_FUELS',
];

const validationSchema = Yup.object().shape({
  asOfDate: Yup.date()
    .transform(function (value, originalValue) {
      if (this.isType(value)) {
        return value;
      }
      const result = parse(originalValue, 'MM/dd/yyyy', new Date());
      return result;
    })
    .required(),
  nonFuelLimits: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().required('Amount is required'),
      quantity: Yup.string().notRequired(),
      asOfDate: Yup.string().nullable(),
      productCategory: Yup.string().required(),
      fuelLimitGallon: Yup.boolean(),
    }),
  ),
  fuelLimits: Yup.array().of(
    Yup.object().shape({
      amount: Yup.string().when('fuelLimitGallon', {
        is: false,
        then: Yup.string().required('Amount is required'),
      }),
      quantity: Yup.string().when('fuelLimitGallon', {
        is: true,
        then: Yup.string().required('Quantity is required'),
      }),
      asOfDate: Yup.string().nullable(),
      productCategory: Yup.string().required(),
    }),
  ),
});

const initialValues = {
  asOfDate: null,
  fuelLimits: [
    {
      label: 'Tractor Diesel',
      productCategory: 'PRODUCT_CATEGORY_DIESEL',
      amount: '',
      quantity: '',
      asOfDate: null,
      fuelLimitGallon: false,
    },
    {
      label: 'Reefer Diesel',
      productCategory: 'PRODUCT_CATEGORY_REEFER',
      amount: '',
      quantity: '',
      asOfDate: null,
      fuelLimitGallon: false,
    },
    {
      label: 'DEF',
      productCategory: 'PRODUCT_CATEGORY_DEF',
      amount: '',
      quantity: '',
      asOfDate: null,
      fuelLimitGallon: false,
    },
    {
      label: 'OTHER FUELS',
      productCategory: 'PRODUCT_CATEGORY_NON_DIESEL_FUELS',
      amount: '',
      quantity: '',
      asOfDate: null,
      fuelLimitGallon: false,
    },
  ],
  nonFuelLimits: [
    {
      label: 'Oil',
      productCategory: 'PRODUCT_CATEGORY_OIL',
      amount: '',
      quantity: '',
      asOfDate: null,
    },
    {
      label: 'Additives',
      productCategory: 'PRODUCT_CATEGORY_ADDITIVES',
      amount: '',
      quantity: '',
      asOfDate: null,
    },
    {
      label: 'Merchandise',
      productCategory: 'PRODUCT_CATEGORY_MERCHANDISE',
      amount: '',
      quantity: '',
      asOfDate: null,
    },
    {
      label: 'Cash Advance',
      productCategory: 'PRODUCT_CATEGORY_CASH_ADVANCE',
      amount: '',
      quantity: '',
      asOfDate: null,
    },
    {
      label: 'Services',
      productCategory: 'PRODUCT_CATEGORY_SERVICES',
      amount: '',
      quantity: '',
      asOfDate: null,
    },
    {
      label: 'Maintenance',
      productCategory: 'PRODUCT_CATEGORY_MAINTENANCE',
      amount: '',
      quantity: '',
      asOfDate: null,
    },
  ],
};

export function CarrierLimits() {
  const [asOfDate, setAsOfDate] = React.useState<Date | null>(null);
  const [formValues, setFormValues] = React.useState(initialValues);
  const navigate = useNavigate();
  const client = useApolloClient();
  const { carrierId = '' } = useParams();

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  if (!carrier) return <div>Carrier not found</div>;

  const handleClose = () => {
    navigate(PathPrivate.Carriers);
  };

  React.useEffect(() => {
    if (carrier.uuid.length === 0) {
      return;
    }

    getLimitsService(carrier.uuid, format(new Date(), 'yyyyMMdd')).then(
      (response) => {
        if (response.data) {
          const fuel: any = [];
          const nonFuel: any = [];
          const returned: any = [];
          response.data.limits.map((item: any) => {
            if (FUEL_PRODUCTS.includes(item.productCategory)) {
              if (item.quantity > 0) {
                item.fuelLimitGallon = true;
              }
            }
            returned.push(item);
            return item;
          });
          initialValues.fuelLimits.map((item: any) => {
            const obj = returned.find(
              (o: { productCategory: any }) =>
                o.productCategory === item.productCategory,
            );
            if (obj != null) {
              if (obj.amount > 0) {
                item.fuelLimitGallon = false;
                item.amount = obj.amount;
              } else if (obj.quantity > 0) {
                item.fuelLimitGallon = true;
                item.quantity = obj.quantity;
              }
              fuel.push(item);
            } else {
              item.fuelLimitGallon = false;
              item.amount = '0';
              item.quantity = '0';
              fuel.push(item);
            }

            return item;
          });

          initialValues.nonFuelLimits.map((item: any) => {
            const obj = returned.find(
              (o: { productCategory: any }) =>
                o.productCategory === item.productCategory,
            );
            if (obj != null) {
              item.amount = obj.amount;
              nonFuel.push(item);
            } else {
              item.amount = '0';
              nonFuel.push(item);
            }
            return item;
          });

          setFormValues((prevState) => {
            return { ...prevState, ...{ fuel, nonFuel } };
          });
        }
      },
    );
  }, [carrier.uuid]);

  return (
    <Formik
      initialValues={formValues || initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        setLimitsService(
          {
            limits: values.nonFuelLimits.concat(values.fuelLimits),
          },
          carrier.uuid,
        )
          .then(() => {
            handleClose();
            toast.success('Limits set successfully');
          })
          .catch((error) => {
            toast.error(`Failed to set limits. Error: ${error}`);
          });
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        resetForm,
        errors,
        setFieldValue,
      }) => (
        <Dialog
          open
          onClose={() => {
            resetForm();
            handleClose();
          }}
        >
          <form onSubmit={handleSubmit}>
            <DialogTitle sx={{ textAlign: 'center' }}>
              Carrier: {carrier.name}
            </DialogTitle>
            <DialogContent dividers>
              <Typography variant="h6" component="h2" mb={2}>
                Set Limit by Products
              </Typography>
              <Alert severity="info" sx={{ mb: 2 }}>
                All fields must be entered before setting Limits.
              </Alert>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Effective date"
                  value={asOfDate}
                  minDate={new Date()}
                  onChange={(newValue: any) => {
                    setAsOfDate(newValue);
                    if (isValid(newValue)) {
                      values.nonFuelLimits.map((_, idx) => {
                        setFieldValue(
                          `nonFuelLimits.${idx}.asOfDate`,
                          format(newValue, 'yyyyMMdd'),
                        );
                      });
                      values.fuelLimits.map((_, idx) => {
                        setFieldValue(
                          `fuelLimits.${idx}.asOfDate`,
                          format(newValue, 'yyyyMMdd'),
                        );
                      });
                      setFieldValue('asOfDate', format(newValue, 'yyyyMMdd'));
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      name="asOfDate"
                      fullWidth
                      {...params}
                      error={!!errors.asOfDate}
                    />
                  )}
                />
              </LocalizationProvider>
              <FieldArray name="fuelLimits">
                {/* @ts-ignore: TS2322 */}
                <List>
                  <>
                    <DialogContentText>Fuel Products</DialogContentText>
                    Dollar
                    <Switch
                      checked={values.fuelLimits[0].fuelLimitGallon}
                      name="fuelLimitGallon"
                      onChange={(event) => {
                        setFieldValue('fuelLimitGallon', event.target.checked);
                        values.fuelLimits.map((l, idx) => {
                          setFieldValue(`fuelLimits.${idx}.amount`, '');
                          setFieldValue(`fuelLimits.${idx}.quantity`, '');
                          setFieldValue(
                            `fuelLimits.${idx}.fuelLimitGallon`,
                            event.target.checked,
                          );
                        });
                      }}
                      color={'default'}
                    />
                    Gallon
                  </>
                  {values.fuelLimits.length > 0 &&
                    values.fuelLimits.map((limit, idx) => {
                      return (
                        <>
                          <Input
                            name={`fuelLimits.${idx}.productCategory`}
                            value={limit.productCategory}
                            hidden
                            key={`fuelLimits.${idx}.productCategory`}
                          />
                          <Input
                            name={`fuelLimits.${idx}.asOfDate`}
                            key={`fuelLimits.${idx}.asOfDate`}
                            value={
                              isValid(asOfDate)
                                ? format(asOfDate || 0, 'yyyyMMdd')
                                : ''
                            }
                            hidden
                          />
                          <ListItem
                            secondaryAction={
                              values.fuelLimits[0].fuelLimitGallon ? (
                                <TextField
                                  variant="standard"
                                  key={`fuelLimits.${idx}.quantity`}
                                  id={`fuelLimits.${idx}.quantity`}
                                  helperText={
                                    Boolean(
                                      errors.fuelLimits &&
                                        errors.fuelLimits[idx],
                                    ) && 'Required field'
                                  }
                                  name={`fuelLimits.${idx}.quantity`}
                                  value={limit.quantity}
                                  error={Boolean(
                                    errors.fuelLimits && errors.fuelLimits[idx],
                                  )}
                                  onChange={handleChange}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        gal
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              ) : (
                                <TextField
                                  variant="standard"
                                  key={`fuelLimits.${idx}.amount`}
                                  id={`fuelLimits.${idx}.amount`}
                                  helperText={
                                    Boolean(
                                      errors.fuelLimits &&
                                        errors.fuelLimits[idx],
                                    ) && 'Required field'
                                  }
                                  name={`fuelLimits.${idx}.amount`}
                                  value={limit.amount}
                                  error={Boolean(
                                    errors.fuelLimits && errors.fuelLimits[idx],
                                  )}
                                  onChange={handleChange}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        $
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              )
                            }
                          >
                            <ListItemText
                              key={`fuelLimits.${idx}`}
                              primary={
                                initialValues.fuelLimits[idx] &&
                                initialValues.fuelLimits[idx].label
                              }
                            />
                          </ListItem>
                        </>
                      );
                    })}
                </List>
              </FieldArray>
              <FieldArray name="nonFuelLimits">
                {/* @ts-ignore: TS2322 */}
                <List>
                  <DialogContentText>Non Fuel Products</DialogContentText>
                  {values.nonFuelLimits.length > 0 &&
                    values.nonFuelLimits.map((limit, idx) => {
                      return (
                        <>
                          <Input
                            name={`nonFuelLimits.${idx}.productCategory`}
                            value={limit.productCategory}
                            hidden
                            key={`nonFuelLimits.${idx}.productCategory`}
                          />
                          <Input
                            name={`nonFuelLimits.${idx}.asOfDate`}
                            key={`nonFuelLimits.${idx}.asOfDate`}
                            value={
                              isValid(asOfDate)
                                ? format(asOfDate || 0, 'yyyyMMdd')
                                : ''
                            }
                            hidden
                          />
                          <ListItem
                            secondaryAction={
                              <TextField
                                variant="standard"
                                key={`nonFuelLimits.${idx}`}
                                id={`nonFuelLimits.${idx}`}
                                helperText={
                                  Boolean(
                                    errors.nonFuelLimits &&
                                      errors.nonFuelLimits[idx],
                                  ) && 'Required field'
                                }
                                name={`nonFuelLimits.${idx}.amount`}
                                value={limit.amount}
                                error={Boolean(
                                  errors.nonFuelLimits &&
                                    errors.nonFuelLimits[idx],
                                )}
                                onChange={handleChange}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      $
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            }
                          >
                            <ListItemText
                              key={`nonFuelLimits.${idx}`}
                              primary={
                                initialValues.nonFuelLimits[idx] &&
                                initialValues.nonFuelLimits[idx].label
                              }
                            />
                          </ListItem>
                        </>
                      );
                    })}
                </List>
              </FieldArray>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleClose}>Close</SecondaryButton>
              <PrimaryButton type="submit">Submit</PrimaryButton>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </Formik>
  );
}
