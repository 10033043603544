import React, { useState, useEffect } from 'react';
import * as auth from '@/aggregator/config/auth';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { PathPrivate } from '@/aggregator/paths/constants';
import { getProgramCreditDetails } from '../../queries';
import { PrimaryButton } from '@/aggregator/ui/inputs/Button';
import toast from 'react-hot-toast';

type CreditDetailsType = {
  programCreditLine: string;
  unallocatedCreditLine: string;
  allocatedCarrierCreditLines: AllocatedCarrierCreditLines;
};

type AllocatedCarrierCreditLines = {
  [carrierName: string]: string;
};

const DataRow = ({ field, value }: { field: string; value: string }) => {
  const parsedValue = parseInt(value);
  const isNegative = value.includes('-');

  const renderValue = () => {
    if (parsedValue < 0) {
      return `-$${Math.abs(parsedValue).toLocaleString()}`;
    }

    if (parsedValue === 0 || parsedValue === null) {
      return '-';
    }

    return `$${parsedValue.toLocaleString()}`;
  };

  return (
    <Box
      p={2}
      mb={2}
      bgcolor="#D3D3D359"
      borderRadius={2}
      justifyContent="space-between"
      display="flex"
      flexDirection="row"
    >
      <Typography>{field}</Typography>
      <Typography color={isNegative ? 'red' : 'black'}>
        {renderValue()}
      </Typography>
    </Box>
  );
};

export const CarrierProgramCreditDetails = () => {
  const [creditDetails, setCreditDetails] = useState<CreditDetailsType | null>({
    programCreditLine: '0',
    unallocatedCreditLine: '0',
    allocatedCarrierCreditLines: {},
  });
  const programUuid = auth.getProgramUuid();
  const navigate = useNavigate();

  const handleOnClose = () => {
    navigate(PathPrivate.Carriers);
  };

  useEffect(() => {
    getProgramCreditDetails(programUuid)
      .then((response) => {
        setCreditDetails(response.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setCreditDetails(null);
      });
  }, []);

  return (
    <Dialog open onClose={handleOnClose} fullWidth>
      <DialogTitle>Program Credit Details</DialogTitle>
      <DialogContent>
        {creditDetails ? (
          <Box>
            <DataRow
              field="Program Credit Line"
              value={creditDetails.programCreditLine}
            />
            <DataRow
              field="Unallocated Credit Line"
              value={creditDetails.unallocatedCreditLine}
            />
            <Typography variant="h6" mt={4} mb={2}>
              Allocated Carrier Credit Lines
            </Typography>
            {Object.entries(creditDetails.allocatedCarrierCreditLines).map(
              ([key, value]) => (
                <DataRow key={key} field={key} value={value} />
              ),
            )}
          </Box>
        ) : (
          <Box>
            <Typography>
              Unable to retrieve program credit line details
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <PrimaryButton onClick={handleOnClose}>Close</PrimaryButton>
      </DialogActions>
    </Dialog>
  );
};
