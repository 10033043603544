import * as React from 'react';
import { Formik, Form } from 'formik';
import { useApolloClient } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import {
  Alert,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
} from '@mui/material';
import toast from 'react-hot-toast';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import {
  setCarrierPromptsService,
  getCarrierPromptsService,
} from '../../queries';

export function CarrierPrompts() {
  const client = useApolloClient();
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({
    hasTruckNumber: false,
    hasTrailerNumber: false,
    hasTripNumber: false,
    hasOdometer: false,
    asOfDate: format(new Date(), 'yyyyMMdd'),
  });
  const { carrierId = '' } = useParams();
  const navigate = useNavigate();

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  const handleClose = () => {
    navigate(PathPrivate.Carriers);
  };

  React.useEffect(() => {
    if (!carrierId) {
      return;
    }

    getCarrierPromptsService(carrierId).then((response) => {
      setData(response.data.prompt);
      setLoading(false);
    });
  }, [carrierId]);

  if (loading) return <CircularProgress />;
  if (!carrier) return <div>Carrier not found</div>;

  return (
    <Formik
      initialValues={data}
      enableReinitialize
      onSubmit={async (values) => {
        const parsedValues = {
          ...values,
          asOfDate: format(new Date(), 'yyyyMMdd'),
        };

        if (!carrierId) {
          toast.error('Unable to save changes. Carrier ID not set');
          handleClose();
          return;
        }

        await setCarrierPromptsService(parsedValues, carrierId)
          .then(() => {
            toast.success('Prompts set');
            handleClose();
          })
          .catch((e: any) => {
            if (e.response) {
              toast.error(e.response.data.message);
            } else {
              toast.error(e.message);
            }
          });
      }}
    >
      {({ values, handleChange, handleSubmit }) => (
        <Dialog open onClose={handleClose} scroll="paper">
          <DialogTitle sx={{ textAlign: 'center' }}>
            Carrier: {carrier.name}
          </DialogTitle>
          <Form onSubmit={handleSubmit}>
            <DialogContent dividers>
              <Box sx={{ mx: 2, my: 1 }}>
                <Typography variant="h6" component="h2" mb={2}>
                  Set Carrier Prompts
                </Typography>
                {data.asOfDate && (
                  <Alert severity="info">
                    {`Prompt was set on ${format(
                      parseISO(data.asOfDate),
                      'MM/dd/yyyy',
                    )}`}
                  </Alert>
                )}
                <FormGroup
                  sx={{ my: 2, display: 'flex', flexDirection: 'row' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: 125,
                    }}
                  >
                    <FormControlLabel
                      label="Truck"
                      control={
                        <Switch
                          name="hasTruckNumber"
                          checked={values.hasTruckNumber}
                          onChange={handleChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Trip"
                      control={
                        <Switch
                          name="hasTripNumber"
                          checked={values.hasTripNumber}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: 125,
                    }}
                  >
                    <FormControlLabel
                      label="Trailer"
                      control={
                        <Switch
                          name="hasTrailerNumber"
                          checked={values.hasTrailerNumber}
                          onChange={handleChange}
                        />
                      }
                    />
                    <FormControlLabel
                      label="Odometer"
                      control={
                        <Switch
                          name="hasOdometer"
                          checked={values.hasOdometer}
                          onChange={handleChange}
                        />
                      }
                    />
                  </Box>
                </FormGroup>
              </Box>
            </DialogContent>
            <DialogActions>
              <SecondaryButton onClick={handleClose}>Cancel</SecondaryButton>
              <PrimaryButton type="submit" onClick={handleSubmit}>
                Save
              </PrimaryButton>
            </DialogActions>
          </Form>
        </Dialog>
      )}
    </Formik>
  );
}
