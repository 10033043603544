import { gql } from '@apollo/client';
import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export const QUERY_GET_MERCHANTS_LOCATIONS = gql`
  query GetMerchantsLocations {
    locations
      @rest(
        type: "MerchantsLocations"
        path: "/staff/merchants/locations"
        endpoint: "merchant"
      ) {
      locations @type(name: "MerchantLocation") {
        uuid
        name
      }
    }
  }
`;

export async function getMerchantsLocations() {
  return await axios
    .get(`${Config.MERCHANT_API_URL}/staff/merchants/locations`)
    .then((response) => response)
    .catch((error) => error);
}
