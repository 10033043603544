import * as React from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { Table } from '@/aggregator/ui/data-display/Table';
import { PrimaryButton } from '@/aggregator/ui/inputs/Button';
import { PathPrivate } from '@/aggregator/paths/constants';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import * as auth from '@/aggregator/config/auth';
import { ActionsMenu } from './components/ActionsMenu';
import { StatusPill } from './components/StatusPill';
import * as queries from './queries';
import * as types from './types';

export function Users() {
  const navigate = useNavigate();
  const location = useLocation();
  const programUuid = auth.getProgramUuid();
  const [loading, setLoading] = React.useState(true);
  const [users, setUsers] = React.useState<types.User[]>([]);

  const getData = async () => {
    if (!loading) {
      setLoading(true);
    }
    await queries.getUsers(programUuid).then((response: any) => {
      setUsers(response.data.users);
      setLoading(false);
    });
  };

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (location.state?.shouldRefetch) {
      getData();
    }
  }, [location.state]);

  const columns = [
    {
      label: 'First',
      name: 'firstName',
    },
    {
      label: 'Last',
      name: 'lastName',
    },
    {
      label: 'Email',
      name: 'email',
    },
    {
      label: 'Phone',
      name: 'phoneNumber',
    },
    {
      label: 'Status',
      name: 'enabled',
      options: {
        customBodyRender: (value: any) => {
          return <StatusPill active={Boolean(value)} />;
        },
      },
    },
    {
      label: ' ',
      name: 'enabled',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex: any) => {
          const user = users[dataIndex];
          const enabled = Boolean(user.enabled);
          return (
            <ActionsMenu
              dataIndex={dataIndex}
              enabled={enabled}
              identityUuid={user.identityUuid}
              getData={getData}
              onClickEnableOrDisable={
                enabled ? queries.deactivateUser : queries.activateUser
              }
            />
          );
        },
      },
    },
  ];

  const handleOnClickAddUser = () => {
    navigate(PathPrivate.UsersAddUser);
  };

  if (loading) return <CircularProgress />;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <PrimaryButton onClick={handleOnClickAddUser}>Add User</PrimaryButton>
      </Box>
      <Table title="Users" data={users} columns={columns} />
      <Outlet />
    </>
  );
}
