import MUIDataTable, {
  MUIDataTableColumnDef,
  MUIDataTableOptions,
} from 'mui-datatables';

type TablePropsType = {
  columns: MUIDataTableColumnDef[];
  data: (object | number[] | string[])[];
  title: string;
  options?: MUIDataTableOptions;
};

export function Table(props: TablePropsType) {
  return (
    <MUIDataTable
      columns={props.columns}
      data={props.data}
      title={props.title}
      // for more options:
      // https://github.com/gregnb/mui-datatables?tab=readme-ov-file#options
      options={{
        // caseSensitive: false,
        // confirmFilters: false,
        download: false,
        enableNestedDataAccess: '.',
        // expandableRows: false,
        // expandableRowsHeader: true,
        // expandableRowsOnClick: false,
        filter: false,
        // filterArrayFullMatch: false,
        // fixedHeader: true,
        // fixedSelectColumn: false,
        // jumpToPage: false,
        pagination: false,
        print: false,
        // resizableColumns: false,
        // rowHover: false,
        search: false,
        // searchOpen: false,
        // searchAlwaysOpen: false,
        selectableRows: 'none', // This line is changed to hide checkboxes
        // selectableRowsHeader: false,
        // selectableRowsHideCheckboxes: false,
        // selectableRowsOnClick: false,
        // serverSide: false,
        sort: true,
        // sortFilterList: false,
        viewColumns: false,
        ...props.options,
      }}
    />
  );
}
