import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { generatePath } from '@/aggregator/paths/helpers';
import { PathPrivate } from '@/aggregator/paths/constants';

type ActionsMenuPropsType = {
  dataIndex: number;
  enabled: boolean;
  identityUuid: string;
  onClickEnableOrDisable: (identityUuid: string) => void;
  getData: () => void;
};

export function ActionsMenu(props: ActionsMenuPropsType) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [menuRowIndex, setMenuRowIndex] = React.useState<number | null>(null);

  const handleOnClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    index: number,
  ) => {
    setAnchorEl(event.currentTarget);
    setMenuRowIndex(index);
  };

  const handleOnClose = () => {
    setAnchorEl(null);
    setMenuRowIndex(null);
  };

  const handleOnClickItem = (path: PathPrivate) => {
    handleOnClose();
    const pathUpdated = generatePath(path, {
      userId: props.identityUuid,
    });
    navigate(pathUpdated);
  };

  const options = [
    {
      name: 'Reset Password',
      path: PathPrivate.UserResetPassword,
    },
    {
      name: `${props.enabled ? 'Deactivate' : 'Activate'}`,
      onClickHandler: async () => {
        await props.onClickEnableOrDisable(props.identityUuid);
        handleOnClose();
        await props.getData();
      },
    },
  ];

  return (
    <div>
      <IconButton
        id="long-button"
        disabled={false}
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(event) => handleOnClick(event, props.dataIndex)}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        keepMounted
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && menuRowIndex === props.dataIndex}
        onClose={handleOnClose}
      >
        {options.map((option) => (
          <MenuItem
            key={option.name}
            onClick={() => {
              if (option.onClickHandler) {
                option.onClickHandler();
              } else {
                handleOnClickItem(option.path);
              }
            }}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
