import { Box, Grid } from '@mui/material';
import { StatCard } from './StatCard';
import * as types from '../types';

type DashboardStatsPropsType = {
  dashboardInfo: types.DashboardInfo;
};

function formatDollarValue(value: string | null): string {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return 'N/A';
  }

  return numberValue === 0 ? '$0' : `$${numberValue.toFixed(2)}`;
}

function formatValue(value: string | number | null): string {
  if (value === null || value === undefined || value === '') {
    return 'N/A';
  }

  if (value === 0 || value === '0') {
    return '0';
  }

  return String(value);
}

function formatGallonValue(value: string | null): string {
  if (value === null || value === undefined) {
    return 'N/A';
  }

  const numberValue = Number(value);
  if (isNaN(numberValue)) {
    return 'N/A';
  }

  return numberValue === 0 ? '0' : `${numberValue.toFixed(3)}`;
}

export function DashboardStats(props: DashboardStatsPropsType) {
  const { dashboardInfo } = props;

  return (
    <Box sx={{ mt: 2 }}>
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            label="Most Active Carrier"
            value={formatValue(dashboardInfo.activeCarrier)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            label="Total Retail Spend"
            value={formatDollarValue(dashboardInfo.totalDollars)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            label="Total Gallons"
            value={formatGallonValue(dashboardInfo.totalGallons)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            label="Total Savings"
            value={formatDollarValue(dashboardInfo.savings)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            label="Total Discounted Spend"
            value={formatDollarValue(dashboardInfo.totalDiscounted)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <StatCard
            label="Total Transactions"
            value={formatValue(dashboardInfo.totalTransactions)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
