import { Container, Typography } from '@mui/material';

export function Denied() {
  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8 }}>
      <Typography component="h1" variant="h5" gutterBottom>
        Access Denied
      </Typography>
      <Typography variant="body1" gutterBottom>
        You do not have permission to view this page. If you believe this is a
        mistake, please contact support.
      </Typography>
    </Container>
  );
}
