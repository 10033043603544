import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import OnrampLogo from '@/aggregator/logo.png';

type LogoPropsType = {
  isMobile?: boolean;
};

export function Logo(props: LogoPropsType) {
  const { isMobile } = props;
  let sxConfig = { display: { xs: 'none', md: 'flex' } };

  if (isMobile) {
    sxConfig = { display: { xs: 'flex', md: 'none' } };
  }

  return (
    <>
      <Avatar alt="Onramp Logo" src={OnrampLogo} sx={{ ...sxConfig, mr: 1 }} />
      <Typography
        variant="h5"
        noWrap={isMobile}
        sx={{
          mr: 2,
          ...sxConfig,
          flexGrow: isMobile ? 1 : 0,
          color: 'inherit',
          textDecoration: 'none',
        }}
      >
        Program
      </Typography>
    </>
  );
}
