import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { PathPublic, PathPrivate } from '@/aggregator/paths/constants';
import { NavLink } from 'react-router-dom';
import { Logo } from './Logo';
import { HeaderMenuMobile } from './HeaderMenuMobile';
import { HeaderMenuDesktop } from './HeaderMenuDesktop';

export const pages = [
  {
    text: 'Dashboard',
    to: PathPrivate.Dashboard,
  },
  {
    text: 'Carriers',
    to: PathPrivate.Carriers,
  },
  {
    text: 'Merchants',
    to: PathPrivate.Merchants,
  },
  {
    text: 'Users',
    to: PathPrivate.Users,
  },
];

export function Header() {
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* mobile menu */}
          <HeaderMenuMobile />

          {/* mobile logo */}
          <Logo isMobile />

          {/* desktop logo */}
          <Logo />

          {/* desktop menu */}
          <HeaderMenuDesktop />

          {/* both menu */}
          <Box sx={{ flexGrow: 0 }}>
            <Button color="inherit" component={NavLink} to={PathPublic.Logout}>
              Logout
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
