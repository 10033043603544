import { Button, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';

type PrimaryButtonPropsType = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
};

type EnhancedPrimaryButtonPropsType = PrimaryButtonPropsType &
  Omit<ButtonProps, 'children' | 'onClick' | 'type' | 'startIcon' | 'endIcon'>;

export function PrimaryButton(props: EnhancedPrimaryButtonPropsType) {
  const { startIcon, endIcon, type, children, onClick, disabled } = props;

  return (
    <Button
      type={type}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      sx={{
        height: 40,
        textTransform: 'none',
        background: '#181818',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#353535',
        },
        ':disabled': {
          background: '#ccc',
        },
        padding: '15px',
      }}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

type SecondaryButtonPropsType = {
  children?: ReactNode;
  onClick?: () => void;
};

type EnhancedSecondaryButtonPropsType = SecondaryButtonPropsType &
  Omit<ButtonProps, 'children' | 'onClick'>;

export function SecondaryButton(props: EnhancedSecondaryButtonPropsType) {
  const { children, onClick } = props;

  return (
    <Button
      onClick={onClick}
      sx={{
        height: 40,
        textTransform: 'none',
        color: '#181818',
        padding: '15px',
      }}
    >
      {children}
    </Button>
  );
}

export const TertiaryButton = ({ type, children, onClick, disabled }: any) => (
  <Button
    type={type}
    onClick={onClick}
    sx={{
      textAlign: 'left',

      height: 40,
      textTransform: 'none',
      borderColor: '#181818',
      color: '#181818',
      '&:hover': {
        borderColor: '#353535',
      },
      ':disabled': {
        background: '#ccc',
      },
      padding: '15px',
    }}
    variant="outlined"
    disabled={disabled}
  >
    {children}
  </Button>
);
