import * as React from 'react';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import { MUIDataTableColumnDef } from 'mui-datatables';
import { Box, Button } from '@mui/material';
import { Table } from '@/aggregator/ui/data-display/Table';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import { PrimaryButton, SecondaryButton } from '@/aggregator/ui/inputs/Button';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';
import { PathPrivate } from '@/aggregator/paths/constants';
import * as queriesCarriers from '@/aggregator/pages/private/carriers/queries';
import * as typesCarriers from '@/aggregator/pages/private/carriers/types';
import { generatePath } from '@/aggregator/paths/helpers';
import * as auth from '@/aggregator/config/auth';
import { ActionsMenu } from './components/ActionsMenu';
import * as queries from './queries';
import * as types from './types';

export function CarrierDrivers() {
  const [drivers, setDrivers] = React.useState<types.DriverType[]>([]);
  const [loading, setLoading] = React.useState(true);
  const client = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  const programUuid = auth.getProgramUuid();
  const { carrierId = '' } = useParams();

  const [getCarriers, queryCarriers] = useLazyQuery<{
    getCarriers: typesCarriers.CarrierType[];
  }>(queriesCarriers.QUERY_GET_CARRIERS, {
    variables: { programUuid },
  });

  const carrier = queriesCarriers.getCarrierFromFragment(client, carrierId);

  const handleImportDriversClick = () => {
    const pathUpdated = generatePath(PathPrivate.CarrierImportDrivers, {
      carrierId: carrierId,
    });
    navigate(pathUpdated);
  };

  const handleAddDriver = () => {
    const pathUpdated = generatePath(PathPrivate.CarrierAddDriver, {
      carrierId: carrierId,
    });
    navigate(pathUpdated);
  };

  const getDriverData = (driverUuid: string) => {
    return drivers.filter((x) => x.uuid === driverUuid)[0];
  };

  const getData = async () => {
    await queries.getCarrierDrivers(carrierId).then((response: any) => {
      if (response.isAxiosError) {
        throw Error(response.response.data.message);
      } else {
        setDrivers(response.data.drivers);
      }
      setLoading(false);
    });
  };

  React.useEffect(() => {
    if (!carrier) {
      getCarriers();
    }
  }, [carrier]);

  React.useEffect(() => {
    getData();
  }, []);

  React.useEffect(() => {
    if (location.state?.shouldRefetch) {
      getData();
    }
  }, [location.state]);

  const columns: MUIDataTableColumnDef[] = [
    {
      label: 'ID',
      name: 'uuid',
    },
    {
      label: 'External ID',
      name: 'driverExternalId',
      options: {
        filter: false,
      },
    },
    {
      label: 'First Name',
      name: 'firstName',
      options: {
        filter: false,
      },
    },
    {
      label: 'Last Name',
      name: 'lastName',
      options: {
        filter: false,
      },
    },
    {
      label: 'Phone',
      name: 'phoneNumber',
      options: {
        filter: false,
      },
    },
    {
      label: 'Email',
      name: 'email',
    },
    {
      label: 'Start date',
      name: 'startDate',
      options: {
        customBodyRender: (value) => {
          return value && format(parseISO(value), 'MM/dd/yyyy');
        },
      },
    },
    {
      label: 'Status',
      name: 'enabled',
      options: {
        filter: true,
        customBodyRender: (enabled) =>
          enabled ? (
            <Button
              size="small"
              sx={{
                color: '#181818',
                textTransform: 'none',
                padding: 0,
                pointerEvents: 'none',
              }}
            >
              <PersonIcon /> Active
            </Button>
          ) : (
            <Button
              sx={{ textTransform: 'none', padding: 0 }}
              size="small"
              disabled
            >
              <PersonOffIcon /> Inactive
            </Button>
          ),
      },
    },
    {
      label: 'Carrier',
      name: 'carrierName',
    },
    {
      label: 'Carrier Public ID',
      name: 'carrierPublicId',
      options: {
        display: false,
      },
    },
    {
      label: ' ',
      name: 'uuid',
      options: {
        filter: false,
        sort: false,
        empty: true,
        customBodyRenderLite: (dataIndex) => {
          return (
            <ActionsMenu
              dataIndex={dataIndex}
              carrierId={drivers[dataIndex].carrierUuid}
              driverId={drivers[dataIndex].uuid}
              driverData={getDriverData(drivers[dataIndex].uuid)}
              getData={getData}
            />
          );
        },
      },
    },
  ];

  if (loading || queryCarriers.loading) return <CircularProgress />;
  if (!carrier) return <div>Carrier not found</div>;

  return (
    <>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <SecondaryButton sx={{ mr: 1 }} onClick={handleAddDriver}>
          Add Driver
        </SecondaryButton>
        <PrimaryButton onClick={handleImportDriversClick}>
          Import Drivers
        </PrimaryButton>
      </Box>
      <Table
        title={`Drivers for Carrier: ${carrier.name}`}
        data={drivers}
        columns={columns}
        options={{
          search: true,
        }}
      />
      <Outlet />
    </>
  );
}
