import { Chip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import HelpIcon from '@mui/icons-material/Help';
import { CarrierReadinessChipPropsType } from '../types';

export function CarrierReadinessChip(props: CarrierReadinessChipPropsType) {
  if (props.readinessStatus === 'ready') {
    return <Chip color="success" label="Ready" icon={<CheckCircleIcon />} />;
  }

  if (props.readinessStatus === 'not_ready') {
    return <Chip color="error" label="Not Ready" icon={<ErrorIcon />} />;
  }

  return (
    <Chip
      color="warning"
      label="Unable to retrieve readiness"
      icon={<HelpIcon />}
    />
  );
}
