export enum DashBoardTime {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
}

export enum DurationFormats {
  MonthDayHour = 'MM/dd HH:mm',
  YearMonthDay = 'yyyy-MM-dd',
}

export type DataPoint = {
  time: string;
  dollar: number;
  gallon: number;
};

export type DashboardInfo = {
  totalGallons: string;
  totalDollars: string;
  savings: string;
  totalTransactions: string;
  activeCarrier: string;
  activePump: string;
  totalDiscounted: string;
  dataPoints: DataPoint[];
};
