import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  HttpLink,
} from '@apollo/client';
import { RestLink } from 'apollo-link-rest';
import * as auth from './auth';
import { Config } from '../config/env';

// Configure REST endpoints
const restLink = new RestLink({
  endpoints: {
    merchant: Config.MERCHANT_API_URL,
    carrier: Config.CARRIER_API_URL,
    report: Config.REPORT_API_URL,
  },
});

// Configure GraphQL endpoint
const httpLink = new HttpLink({
  uri: Config.GQL_URL,
});

const authMiddleware = new ApolloLink((operation, forward) => {
  const token = auth.getToken();

  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Credentials': 'true',
        Authorization: token ? `Bearer ${token}` : '',
      },
    };
  });

  return forward(operation);
});

// RestLink must come before HttpLink GraphQL
const link = ApolloLink.from([authMiddleware, restLink, httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache({
    typePolicies: {
      ExportMerchant: {
        keyFields: ['uuid'],
      },
      ExportCarrier: {
        keyFields: ['uuid'],
        fields: {
          disabledMerchants: {
            read(currentData) {
              return currentData || [];
            },
          },
          disabledLocations: {
            read(currentData) {
              return currentData || [];
            },
          },
        },
      },
    },
  }),
});
