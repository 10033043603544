import * as React from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Mixpanel, initMixpanel } from '@/aggregator/config/mixpanel';
import * as auth from '@/aggregator/config/auth';
import { Header } from './Header';

const Main = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  background: '#',
  marginLeft: 0,
  p: 1,
  '& .MuiButton-root': {
    textTransform: 'none',
  },
  '& .MuiTableCell-root': {
    paddingTop: 8,
    paddingBottom: 8,
  },
  '& .MuiTableCell-head': {
    paddingLeft: 0,
    marginLeft: 10,
  },
}));

export function LayoutPrivate() {
  React.useEffect(() => {
    initMixpanel();
    Mixpanel.identify(auth.getUserEmail());
  }, []);

  return (
    <>
      <Header />
      <Main>
        <Outlet />
      </Main>
    </>
  );
}
