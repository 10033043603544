import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { NavLink } from 'react-router-dom';
import { pages } from './Header';

export function HeaderMenuDesktop() {
  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
      {pages.map((page) => (
        <Button
          key={page.text}
          component={NavLink}
          to={page.to}
          color="inherit"
          sx={{
            my: 2,
            color: 'white',
            display: 'block',
            '&.active': {
              backgroundColor: 'rgba(255, 255, 255, 0.12)',
            },
          }}
        >
          {page.text}
        </Button>
      ))}
    </Box>
  );
}
