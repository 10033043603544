import * as React from 'react';
import * as auth from '@/aggregator/config/auth';
import { CircularProgress } from '@/aggregator/ui/feedback/CircularProgress';

export function Login() {
  React.useEffect(() => {
    auth.doLogin();
  }, [auth.doLogin]);

  return <CircularProgress />;
}
