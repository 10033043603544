import axios from 'axios';
import { Config } from '@/aggregator/config/env';

export async function getUsers(programUuid: string) {
  return await axios
    .get(`${Config.CARRIER_API_URL}/programs/${programUuid}/users`)
    .then((response) => response)
    .catch((error) => error);
}

export async function deactivateUser(identityUuid: string) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/users/${identityUuid}/deactivate`)
    .then((response) => response)
    .catch((error) => error);
}

export async function activateUser(identityUuid: string) {
  return await axios
    .post(`${Config.CARRIER_API_URL}/users/${identityUuid}/activate`)
    .then((response) => response)
    .catch((error) => error);
}
