import { Box, Typography } from '@mui/material';

type StatCardPropsType = {
  label: string;
  value: string | number;
};

export function StatCard(props: StatCardPropsType) {
  const { label, value } = props;

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        borderRadius: 2,
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        p: 2,
        minWidth: 150,
        textAlign: 'center',
      }}
    >
      <Typography variant="h6">{label}</Typography>
      <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 24 }}>
        {value}
      </Typography>
    </Box>
  );
}
