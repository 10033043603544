import * as React from 'react';
import { Box } from '@mui/material';
import { format, utcToZonedTime } from 'date-fns-tz';
import { Line } from 'react-chartjs-2';
import * as types from '../../../types';

type TransactionChartPropsType = {
  chartData: types.DataPoint[];
  xAxisFormat: types.DurationFormats;
};

export function TransactionChart(props: TransactionChartPropsType) {
  const { chartData, xAxisFormat } = props;

  const parsedData = React.useMemo(() => {
    return chartData.map((d: types.DataPoint) => {
      const zonedDate = utcToZonedTime(
        d.time,
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      ); // Convert UTC time to the local time zone
      return {
        x: format(zonedDate, xAxisFormat),
        gallon: d.gallon,
        dollar: d.dollar,
      }; // Include both gallon and dollar values
    });
  }, [chartData, xAxisFormat]);

  const data = {
    type: 'line',
    datasets: [
      {
        label: 'Gallons',
        data: parsedData.map((d) => ({ x: d.x, y: d.gallon })),
        yAxisID: 'y-gallon',
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
      },
      {
        label: 'Dollars',
        data: parsedData.map((d) => ({ x: d.x, y: d.dollar })),
        yAxisID: 'y-dollar',
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        ticks: {
          maxTicksLimit: 8,
        },
        grid: {
          display: false,
        },
      },
      'y-gallon': {
        type: 'linear' as const,
        position: 'left' as const,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Gallons',
        },
      },
      'y-dollar': {
        type: 'linear' as const,
        position: 'right' as const,
        grid: {
          display: false,
        },
        title: {
          display: true,
          text: 'Dollars',
        },
        // Ensure the dollar scale starts at 0
        beginAtZero: true,
      },
    },
  };

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '250px',
        maxHeight: '300px',
      }}
    >
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        <Line
          data={data}
          options={options}
          style={{ height: '300px', width: '100%' }}
        />
      </Box>
    </Box>
  );
}
